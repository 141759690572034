import { useMutation } from '@apollo/client'
import FooterUI, { Props } from '@interflora/ui-components/build/components/Footer/Footer'
import React, { useContext } from 'react'
import { populateTemplateValues } from 'utils/components'
import { HandleSubmit, NewsletterSignUpValues } from '@interflora/ui-components/build/common/props'
import { NEWSLETTER_SIGNUP } from '../../graphql/queries'
import _get from 'lodash/get'
import AnalyticsContext from 'context/AnalyticsContext'
import { usePathname } from 'next/navigation'

type FooterProps = Omit<Props, 'onSubmit' & 'signUpConfirm'> & { newsLetterSignUp: any }

const updateSignUpTemplate: NewsletterSignUpValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const Footer = (props: FooterProps) => {
  const { newsLetterSignUp, ...others } = props
  const [signUpMutation] = useMutation(NEWSLETTER_SIGNUP)
  const analytics = useContext(AnalyticsContext)
  const location = usePathname()

  const newsLetterSignUpSubmitAnalytics = (location?: string) => {
    analytics.newsLetterSignUpSubmit(location)
  }

  const newsLetterSignUpOpenAnalytics = (location: string) => {
    analytics.newsLetterSignUpOpen(location)
  }
  const signUp: HandleSubmit<NewsletterSignUpValues> = async (values) => {
    try {
      const signUpValues = { ...values, email: values.email.trim() }
      const signUpNewsletterValues = populateTemplateValues(signUpValues, updateSignUpTemplate)
      await signUpMutation({ variables: { input: signUpNewsletterValues } })
      newsLetterSignUpSubmitAnalytics(location)
    } catch (error) {
      const errMsg = _get(error, ['graphQLErrors', 0, 'message'])
      throw new Error(errMsg)
    }
  }

  const sendFooterAnalytics = (linkText: string) => {
    if (linkText) {
      analytics.sendFooterAnalytics(linkText)
    }
  }

  return (
    <FooterUI
      signUp={signUp}
      newsletterSignUp={newsLetterSignUp}
      sendFooterAnalytics={sendFooterAnalytics}
      newsLetterSignUpOpenAnalytics={newsLetterSignUpOpenAnalytics}
      {...others}
    />
  )
}

export default Footer
